.root {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-grey-medium);
    padding: 10px 0;
    cursor: pointer;
    text-decoration: none;
    color: #231F20
}

.image {
    flex:0 0 55px;
    min-height: 55px;
    margin-right: 10px;
}

.info_price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex: 1 1 auto;
}

.info {
    display: flex;
    flex-direction: column;
    flex: 1 0;
}

.info strong {
    width: 190px;
    color: var(--color-primary);
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    text-transform: uppercase;
}

.prices {
    display: inline-flex;
    flex:0 0 65px;
    flex-direction: column;
    align-items: flex-end;
}

.price {
    font-weight: 800;
    line-height: 13px;
}

.price_from {
    text-decoration: line-through;
    line-height: 13px;
}

.icon {
    display: none;
}


@media (min-width: 640px) {
    .info strong {
        width: 300px; 
    }
}

@media (min-width: 768px) {

    .info strong {
        width: auto; 
        white-space: unset;
        overflow: unset;
        text-overflow: unset; 
    }

    .info_price {
        flex-direction: column;
        align-items: unset;
    }

    .prices {
        display: inline-flex;
        flex-direction: row;
        align-items: flex-end;
        flex: unset;
        margin-top: 10px;
    }

    .price_from {
        margin-right: 10px;
    }

    .icon {
        display: inline-flex;
        margin-left: 6%;
    }

    .image {
        margin-right: 10px;
    }
    
}

@media (min-width: 1024px) {

    .info_price {
        align-items: center;
        flex-direction: row;
    }

    .prices {
        display: inline-flex;
        align-items: flex-end;
        flex-direction: row;
        margin-top: 0px;
    }
  
    .image {
        margin-right: 20px;
    }
    
}