.item {
    min-width: 260px;
}

@media (min-width: 640px) {
    .item {
        flex: 0 0 15.60734463276836%;
        min-width: 220px;
    }
}

@media (min-width: 768px) {
  
}

@media (min-width: 1024px) {
   
}

@media (min-width: 1440px) {
 
}